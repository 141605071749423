//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
html,
body,
main,
.container-fluid,
.content {
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
}

.navbar-top {
  margin-right: 0;
}


.kanban-column {
  // width: 272px; // trello uses this width
  width: 13vw;
}
.kanban-item {
  margin-top: map_get($spacers, 1);
}
.kanban-items-container {
  padding: map_get($spacers, 1);
}

.tooltip-inner {
  max-width: 450px;
  max-height: 300px;
  overflow: hidden;
}

// this mimics the falcon input group
.react-datepicker-wrapper {
  flex: 1 1 auto;
  width: 1%;
}

.nav-link {
  color: $gray-700 !important;
}

.nav-link:hover {
  color: $gray-900 !important;
}

.nav-link.active {
  color: $primary !important;
}

.nav-link-icon.nav-link-dropdown-item {
  width: 2.5rem !important;
  line-height: 1;
}
